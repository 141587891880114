import FormCustomizeStep from './form_customize_step.vue';
import Form, { formCreate } from '../../../../../components/form';

formCreate.component('FormCustomizeStep', FormCustomizeStep);
export default {
  name: 'form_customize_step',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
};
