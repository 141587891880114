// import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';
import request from '../../../../utils/request';
import Form from './components/form';

export default {
  name: 'conditionStub',
  extends: BasePage,
  components: {
    FormC: Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      cellClickConfig: {
        functionCode: 'stub_add',
        queryUrl: '/mdm/mdmPriceConditionTypeController/query',
      },
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setColumn(col) {
      const rowData = col;

      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => {
          if (row.updateDate) {
            return `${row.updateDate} ${row.updateDateSecond}`;
          }
          return '';
        };
      }
      return rowData;
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @returns {any}
     * @param field
     */
    setFormField(field) {
      // console.log(field);
      if (field.field === 'conditionTypeCode') {
        Object.assign(field, {
          props: {
            disabled: this.modalConfig.config.buttonCode !== 'add',
          },
          validate: [
            {
              pattern: '^[a-zA-Z0-9]*$',
              message: '条件类型编码仅支持输入英文和数字',
              trigger: 'blur',
            },
            {
              required: true,
              message: '请输入分类编码',
              trigger: 'blur',
            },
          ],
        });
      }
      if (field.field === 'conditionTypeRelGroupReqVoList') {
        if (this.modalConfig.config.buttonCode === 'edit') {
          this.modalConfig.title = '编辑';
        } else if (this.modalConfig.config.buttonCode === 'add') {
          this.modalConfig.title = '新增';
        } else if (this.modalConfig.config.buttonCode === 'view') {
          this.modalConfig.title = '查看';
        }
        Object.assign(field, {
          col: {
            md: {
              span: 24,
            },
          },
        });
      }
      return { ...field };
    },
    async beforeModalClick({ val, row }, rule) {
      this.formName = 'FormC';
      this.formConfig = { ...this.formConfig, code: val.buttonCode };
      if (val.buttonCode === 'edit') {
        const res = await request.get('/mdm/mdmPriceConditionTypeController/editCheck', { id: row.id });
        if (res.success) {
          if (res.result.editCheck) {
            return { val, row, rule };
          }
          this.$message.error(res.result.message);
          return false;
        }
      }
      if (val.buttonCode === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig = Object.assign(this.modalConfig, {
          config: {
            ...this.modalConfig.config,
            code: 'view',
          },
        });
        rule.push({
          field: 'createName',
          title: '创建人',
          type: 'input',
          value: row.createName,
          col: {
            md: {
              span: 12,
            },
          },
        });
        rule.push({
          field: 'createDate',
          title: '创建时间',
          type: 'input',
          value: `${row.createDate} ${row.createDateSecond}`,
          col: {
            md: {
              span: 12,
            },
          },
        });
      }
      // rule.forEach((v) => {
      //   const item = v;
      //   if (item.field === 'conditionTypeRelGroupReqVoList') {
      //     item.value = this.modalFormData.conditionTypeRelGroupReqVoList;
      //   }
      // });
      // console.log(rule);
      // console.log(this.modalFormData);
      return { val, row, rule };
    },
  },
};
