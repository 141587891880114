<template>
  <div class="container">
    <!-- <div>{{ title }}</div> -->
    <el-row :gutter="0">
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <el-button :disabled="disabled" icon="el-icon-circle-plus" type="text" @click="addContact">添加一行</el-button>
          <div class="form-item" v-for="(item, k) in value" :key="k">
            <label class="label">条件分类{{k+1}}</label>
            <el-select class="select" :disabled="disabled" clearable v-model="item.conditionGroupCode" placeholder="请选择" @change="changeFn(k,item)">
              <el-option
                v-for="(item, j) in options"
                :key="j"
                :label="item.conditionGroupDesc"
                :value="item.conditionGroupCode"
              >
              </el-option>
            </el-select>
            <i class="el-icon-delete"  @click="deleteContact(k)" v-if="!disabled"></i>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from '../../../../../utils/request';

export default {
  name: 'formCustomizeStep',
  props: {
    value: Array,
    default: () => [],
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    value(v) {
      console.log(v);
    },
  },
  data() {
    return {
      title: '存取顺序',
      options: [],
    };
  },
  created() {
    this.getOption();
  },
  methods: {
    // 获取下拉数据
    getOption() {
      request.post('/mdm/mdmPriceConditionGroupController/findCollection').then((res) => {
        if (res.success) {
          this.options = res.result;
        }
      });
    },
    // 新增一行
    addContact() {
      const list = this.value || [];
      list.push({
        conditionGroupDesc: '',
        conditionGroupCode: '',
      });
      this.$emit('input', list);
    },
    // 删除一行
    deleteContact(index) {
      const list = this.value || [];
      list.splice(index, 1);
      this.$emit('input', list);
    },
    // 下拉监听
    changeFn(k, v) {
      const list = this.value;
      const subItem = this.options.filter((item) => item.conditionGroupCode === v.conditionGroupCode);
      console.log(subItem);
      list[k].conditionGroupDesc = subItem[0].conditionGroupDesc;

      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scoped>
.container{
  // max-width: 90%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow: hidden;
  /deep/ .el-row{
    margin: 0 !important;
    padding: 0 !important;
  }
  .plus{
    margin-bottom: 10px;
  }
  .form-item{
    position: relative;
    margin-bottom:15px;
    display: flex;
    align-items: center;
    .label{
      position: absolute;
      top: 0;
      left: -64px;
    }
    .select{
      width: calc(100% - 60px);
      margin-right: 20px;
    }
  }
}
  /deep/ .el-col-8{
    padding-left: 0px !important;
  }
</style>
